<template>
  <van-dialog
    v-bind="$attrs"
    class-name="exceptions-dialog to-inner-dialog"
    show-cancel-button
    @close="onClose"
    @open="onOpen"
    v-on="$listeners"
  >
    <div slot="title" class="title">
      <span>内部处理</span>
      <!--      <van-icon-->
      <!--        class="close-icon"-->
      <!--        name="mengcengguanbi"-->
      <!--        class-prefix="iconfont"-->
      <!--        @click.native="$emit('close')"-->
      <!--      />-->
    </div>
    <van-form
      ref="observer"
      :show-error-message="false"
      label-width="80px"
      class="van-form__text-color"
      colon
    >
      <van-field
        :rules="[{ required: true, message: '请填写异常分类' }]"
        :value="info.abnormalTypeName"
        clickable
        label="异常分类"
        name="abnormalType"
        placeholder="请选择"
        input-align="right"
        is-link
        readonly
        required
        @click="handlerTypesClick($event, 'abnormalType')"
      />
      <van-field
        :rules="[{ required: true, message: '请选择处理人' }]"
        :value="info.handlerUserName"
        clickable
        label="处理人"
        name="handlerUserId"
        placeholder="请选择"
        input-align="right"
        is-link
        readonly
        required
        @click="handlerTypesClick($event, 'handlerUserId')"
      />
      <van-field label="处理办法" readonly />
      <van-field
        v-model="info.disposeMethod"
        class="dispose-method-label"
        :autosize="{ maxHeight: 200 }"
        label="处理办法"
        maxlength="500"
        name="gist"
        placeholder="请输入内容"
        rows="2"
        border
        show-word-limit
        type="textarea"
      />
    </van-form>
    <div class="dialog-button-group">
      <van-button class="cancel-button" @click="$emit('close')"
        >取消</van-button
      >
      <van-button class="confirm-button" @click="onSubmit">提交</van-button>
    </div>
    <select-popup
      :choose-value="popupValue"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '70vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </van-dialog>
</template>

<script>
import { getPersonList, toInner, toTrouble } from "@/api/psm/inspectionNew";
import SelectPopup from "@/components/SelectPopup";
import { operateMessage } from "@/utils";

export default {
  name: "ToInnerDialog",
  components: { SelectPopup },
  props: {
    errorTypes: {
      type: Array,
      default: () => []
    },
    checkId: String,
    department: String
  },
  data() {
    return {
      info: { abnormalType: "4", abnormalTypeName: "其他" },
      personList: [],
      popupType: "",
      popupTypeName: "",
      popupValue: "",
      popupData: [],
      popupTitle: "",
      popupVisible: false,
      popupTypesConf: {
        abnormalType: {
          title: "异常分类",
          keyName: "abnormalTypeName",
          typesKey: "errorTypes"
        },
        handlerUserId: {
          title: "处理人",
          keyName: "handlerUserName",
          typesKey: "personList"
        }
      }
    };
  },
  methods: {
    async getPersonList() {
      this.personList = await getPersonList({ department: this.department });
    },
    onClose() {
      this.$refs.observer.resetValidation();
    },
    onOpen() {
      this.info.abnormalType = "4";
      this.info.patrolCheckResultId = this.checkId;
      this.getPersonList();
    },
    handlerTypesClick(ev, key) {
      // 如果之前被选中了，那就直接传递过去
      this.popupValue = this.info[key];

      const popupTypesConf = this.popupTypesConf;
      this.popupVisible = true;
      this.popupTitle = popupTypesConf[key].title;

      this.popupData = [...this[popupTypesConf[key].typesKey]];
      this.popupType = key;
      this.popupTypeName = popupTypesConf[key].keyName;
    },
    onPopupConfirm(row) {
      this.info[this.popupType] = row.value;
      this.info[this.popupTypeName] = row.label;
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    async onSubmit() {
      try {
        const v = await this.$refs.observer.validate();
        if (v?.length) return;
        const r = await toInner(this.info);
        operateMessage(!!r, "保存");
        if (r) {
          this.$emit("close");
          this.$emit("refresh");
        }
      } catch (e) {
        console.log("onSubmit -> e", e);
      }
    }
  }
};
</script>

<style lang="scss">
.to-inner-dialog {
  .van-dialog__content {
    //padding-bottom: 24px;
  }
  .van-cell {
    margin-bottom: 10px;
  }
  .dispose-method-label {
    border: 1px solid #e1e3e8;
    width: inherit;
    margin: 0 16px;
    textarea::placeholder {
      color: #cfd2d9;
    }
    .van-field__label {
      display: none;
    }
  }
}
</style>
