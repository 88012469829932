<template>
  <van-dialog
    v-bind="$attrs"
    class-name="exceptions-dialog exceptions-detail-dialog"
    @close="onClose"
    @open="onOpen"
    v-on="$listeners"
  >
    <div slot="title" class="title">
      <span>故障跟踪</span>
      <van-icon
        class="close-icon"
        name="mengcengguanbi"
        class-prefix="iconfont"
        @click.native="$emit('close')"
      />
    </div>
    <van-form
      :show-error-message="false"
      label-width="80px"
      colon
      class="van-form__text-color"
    >
      <van-field
        v-model="info.description"
        disabled
        label="故障描述"
        :border="false"
      >
        <template #input>
          <p class="desc" @click="goFault()">
            {{ info.description }}
          </p>
        </template>
      </van-field>
      <van-field
        v-model="info.equipmentRecordName"
        disabled
        label="关联设备"
        :border="false"
      />
      <van-field
        v-model="info.statusName"
        disabled
        label="故障状态"
        :border="false"
      />
      <van-field
        v-model="info.ticketWorkNo"
        disabled
        label="工单编号"
        :border="false"
      >
        <template #input>
          <p class="desc" @click="goWorkOrder()">
            {{ info.ticketWorkNo }}
          </p>
        </template>
      </van-field>
      <van-field
        v-model="info.ticketWorkStatusName"
        disabled
        label="工单状态"
        :border="false"
      />
    </van-form>
  </van-dialog>
</template>

<script>
import { getFaultInfoById, getTicketsInfoById } from "@/api/psm/inspectionNew";

export default {
  name: "FaultTrackDialog",
  props: ["checkId"],
  data() {
    return {
      info: {}
    };
  },
  methods: {
    goFault() {
      this.$router.push({
        name: "DeviceFaultDetail",
        params: {
          id: this.info.id
        },
        query: {
          name: this.info.description,
          no: this.info.ticketWorkNo
        }
      });
    },
    goWorkOrder() {
      this.$router.push({
        name: "workorderLinkDetail",
        params: {
          id: this.info.ticketId
        }
      });
    },
    onClose() {
      this.info = {};
    },
    onOpen() {
      this.getInfo();
    },
    async getInfo() {
      let res = await getFaultInfoById(this.checkId);
      res.equipmentRecordName = res?.equipmentRecordName?.toString() ?? "";

      let ticketInfo = null;
      if (res.ticketId) {
        ticketInfo = await getTicketsInfoById(res.ticketId);
      }
      this.info = {
        ...res,
        ticketId: ticketInfo?.id || "",
        ticketWorkNo: ticketInfo?.workNo || "",
        ticketWorkStatusName: ticketInfo?.statusName || ""
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.desc {
  width: 100%;
  color: #1989fa;
}
</style>
