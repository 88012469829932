<template>
  <van-dialog
    v-bind="$attrs"
    class-name="exceptions-dialog to-inner-dialog"
    show-cancel-button
    @close="onClose"
    @open="onOpen"
    v-on="$listeners"
  >
    <div slot="title" class="title">
      <span>故障上报</span>
      <!--      <van-icon-->
      <!--        class="close-icon"-->
      <!--        name="mengcengguanbi"-->
      <!--        class-prefix="iconfont"-->
      <!--        @click.native="$emit('close')"-->
      <!--      />-->
    </div>
    <van-form
      ref="observer"
      :show-error-message="false"
      label-width="80px"
      class="van-form__text-color"
      colon
      @submit="onSubmit"
    >
      <van-field
        :rules="[{ required: true, message: '请选择异常分类' }]"
        :value="info.abnormalTypeName"
        is-link
        clickable
        label="异常分类"
        input-align="right"
        name="abnormalType"
        placeholder="请选择"
        readonly
        required
        @click="handlerTypesClick($event, 'abnormalType')"
      />
      <van-field label="故障描述" readonly required />
      <van-field
        v-model="info.description"
        class="dispose-method-label"
        :autosize="{ maxHeight: 200 }"
        label="故障描述"
        maxlength="500"
        name="gist"
        placeholder="请输入内容"
        rows="2"
        border
        :rules="[{ required: true, message: '请填写故障描述' }]"
        show-word-limit
        type="textarea"
      />
      <van-field
        v-model="info.typeName"
        is-link
        label="故障类型"
        readonly
        placeholder="请选择"
        input-align="right"
        required
        :rules="[{ required: true, message: '请选择故障类型' }]"
        @click="faultVisible = true"
      />
      <van-field
        v-model="info.equipmentRecordName"
        label="设备名称"
        is-link
        readonly
        required
        :rules="[{ required: true, message: '请选择设备' }]"
        placeholder="请选择"
        input-align="right"
        @click.native="deviceVisible = true"
      />

      <!--      <van-field-->
      <!--        :rules="[{ required: true, message: '请选择处理人' }]"-->
      <!--        :value="info.handlerUserName"-->
      <!--        clickable-->
      <!--        label="处理人"-->
      <!--        name="handlerUserId"-->
      <!--        placeholder="请选择"-->
      <!--        readonly-->
      <!--        required-->
      <!--        @click="handlerTypesClick($event, 'handlerUserId')"-->
      <!--      />-->
      <div class="dialog-button-group">
        <van-button class="cancel-button" @click="$emit('close')"
          >取消</van-button
        >
        <van-button class="confirm-button" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <select-popup
      :choose-value="popupValue"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '70vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />

    <SelectPopupGrade
      ref="selectPopupGrade"
      v-model="info.typeName"
      :multi="false"
      :immediately="true"
      get-container="body"
      popup-height="70vh"
      title="故障类型"
      :list="faultTypes"
      list-key="value"
      :visible="faultVisible"
      @change="popupGradeChange"
      @close="faultVisible = false"
    />
    <DevicePopup
      popup-height="70%"
      get-container="body"
      :visible="deviceVisible"
      @close="deviceVisible = false"
      @change="onDeviceChange"
    />
  </van-dialog>
</template>

<script>
import { getPersonList, toFault } from "@/api/psm/inspectionNew";
import SelectPopup from "@/components/SelectPopup";
import { operateMessage } from "@/utils";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import DevicePopup from "@/components/DevicePopup";
import { getFaultType } from "@/api/basics/equipment";

export default {
  name: "ToFaultDialog",
  components: {
    SelectPopup,
    SelectPopupGrade,
    DevicePopup
  },
  props: {
    errorTypes: {
      type: Array,
      default: () => []
    },
    checkId: String,
    department: String
  },
  data() {
    return {
      info: {
        abnormalType: "4",
        abnormalTypeName: "其他"
      },
      deviceVisible: false,
      faultVisible: false,
      faultTypes: [],

      personList: [],
      popupType: "",
      popupTypeName: "",
      popupValue: "",
      popupData: [],
      popupTitle: "",
      popupVisible: false,
      popupTypesConf: {
        abnormalType: {
          title: "异常分类",
          keyName: "abnormalTypeName",
          typesKey: "errorTypes"
        }
        // handlerUserId: {
        //   title: "处理人",
        //   keyName: "handlerUserName",
        //   typesKey: "personList"
        // }
      }
    };
  },
  methods: {
    getFaultType() {
      // 获取故障类型
      getFaultType().then(res => {
        this.faultTypes = res;
      });
    },
    popupGradeChange(v) {
      console.log("v", v);
      const [item] = v;
      this.info.type = item.value;
      this.info.typeName = item.label;
    },
    onDeviceChange(v) {
      this.info.equipmentRecordId = v.id;
      this.info.equipmentRecordName = v.name;
    },

    async getPersonList() {
      this.personList = await getPersonList({ department: this.department });
    },
    onClose() {
      this.$refs.observer.resetValidation();
    },
    onOpen() {
      this.info.abnormalType = "4";
      this.info.patrolCheckResultId = this.checkId;
      this.getFaultType();
      // this.getPersonList();
    },
    handlerTypesClick(ev, key) {
      // 如果之前被选中了，那就直接传递过去
      this.popupValue = this.info[key];

      const popupTypesConf = this.popupTypesConf;
      this.popupVisible = true;
      this.popupTitle = popupTypesConf[key].title;

      this.popupData = [...this[popupTypesConf[key].typesKey]];
      this.popupType = key;
      this.popupTypeName = popupTypesConf[key].keyName;
    },
    onPopupConfirm(row) {
      this.info[this.popupType] = row.value;
      this.info[this.popupTypeName] = row.label;
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    async onSubmit() {
      try {
        this.info.equipmentRecordId = [this.info.equipmentRecordId];
        const r = await toFault(this.info);
        operateMessage(!!r, "保存");
        if (r) {
          this.$emit("close");
          this.$emit("refresh");
        }
      } catch (e) {
        console.log("onSubmit -> e", e);
      }
    }
  }
};
</script>

<style lang="scss">
.to-inner-dialog {
  .van-dialog__content {
    //padding-bottom: 24px;
  }
  .van-cell {
    margin-bottom: 10px;
  }
  .dispose-method-label {
    border: 1px solid #e1e3e8;
    width: inherit;
    margin: 0 16px;
    textarea::placeholder {
      color: #cfd2d9;
    }
    .van-field__label {
      display: none;
    }
  }
}
</style>
