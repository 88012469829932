<template>
  <van-dialog
    v-bind="$attrs"
    class-name="exceptions-dialog exceptions-detail-dialog"
    @close="onClose"
    @open="onOpen"
    v-on="$listeners"
  >
    <div slot="title" class="title">
      <span>隐患跟踪</span>
      <van-icon
        class="close-icon"
        name="mengcengguanbi"
        class-prefix="iconfont"
        @click.native="$emit('close')"
      />
    </div>
    <van-form
      :show-error-message="false"
      label-width="80px"
      class="van-form__text-color"
    >
      <van-field
        disabled
        label="隐患名称"
        colon
        :border="false"
        type="textarea"
        :rows="1"
        :autosize="true"
      >
        <template #input>
          <span
            :class="[info.name && 'text-color-primary']"
            @click="toTroublePage"
            >{{ info.name || "--" }}</span
          >
        </template>
      </van-field>
      <van-field disabled label="隐患状态" colon :border="false">
        <template #input>
          <span>{{ info.disposeStatusName || "--" }}</span>
        </template>
      </van-field>
      <van-field disabled label="审批记录" colon :border="false" />
      <trouble-record :id="dataId" dialog class="exceptions-trouble-record" />
    </van-form>
  </van-dialog>
</template>

<script>
import { getTroubleInfoById } from "@/api/psm/trouble";
import TroubleRecord from "@/views/trouble/trouble-fix/components/TroubleRecord";

export default {
  name: "TroubleTrackDialog",
  components: { TroubleRecord },
  props: ["dataId"],
  data() {
    return {
      info: {}
    };
  },
  methods: {
    onClose() {
      this.info = {};
    },
    onOpen() {
      this.getInfo();
    },
    async getInfo() {
      this.info = await getTroubleInfoById(this.dataId);
    },
    toTroublePage() {
      this.$router.push({
        name: "trouble-details",
        params: { id: this.dataId }
      });
    }
  }
};
</script>

<style lang="scss">
.exceptions-dialog {
  [class*="van-hairline"]::after {
    display: none;
  }

  .exceptions-trouble-record {
    .trouble-flow-steps {
    }
  }
}
</style>
