import { whetherDic } from "@/utils/constant";

export const labelConf = [
  { label: "任务名称", prop: "recordName" },
  { label: "任务类型", prop: "taskTypeName" },
  { label: "生效时间", prop: "time" },
  { label: "所属部门", prop: "departmentName" },
  { label: "执行人", prop: "executePersonName" },
  {
    label: "巡检周期",
    prop: "cycleUnints",
    dic: {
      0: "小时",
      1: "每天",
      2: "每周",
      3: "每月"
    }
  },
  { label: "巡检时间", prop: "inspectionTimeBetween" },
  { label: "按巡检点顺序", prop: "sortedStatus", dic: whetherDic },
  { label: "打卡方式", prop: "signTypeName" },
  { label: "实际开始时间", prop: "actualStartTime" },
  { label: "实际结束时间", prop: "actualEndTime" },
  { label: "同行人", prop: "partnerName" },
  { label: "是否补录", prop: "supplementFlag", dic: whetherDic },
  { label: "检查项目", prop: "name" },
  { label: "检查标准", prop: "checkStandardText" },
  { label: "处理方式", prop: "disposeMethod" },
  { label: "情况描述", prop: "actualDesc" },
  { label: "结果", prop: "verdictName" },
  { label: "异常类型", prop: "abnormalTypeName" }
];
