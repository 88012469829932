<template>
  <van-dialog
    v-bind="$attrs"
    class-name="exceptions-dialog exceptions-detail-dialog"
    @close="onClose"
    @open="onOpen"
    v-on="$listeners"
  >
    <div slot="title" class="title">
      <span>处理记录</span>
      <van-icon
        class="close-icon"
        name="mengcengguanbi"
        class-prefix="iconfont"
        @click.native="$emit('close')"
      />
    </div>
    <van-form
      :show-error-message="false"
      label-width="80px"
      colon
      class="van-form__text-color"
    >
      <van-field
        v-model="info.abnormalTypeName"
        disabled
        label="异常分类"
        :border="false"
      />
      <van-field
        v-model="info.handlerUserName"
        disabled
        label="处理人"
        :border="false"
      />
      <van-field
        v-model="info.disposeMethod"
        disabled
        label="处理方法"
        :border="false"
        type="textarea"
        :rows="1"
        :autosize="{ maxHeight: 200 }"
      />
    </van-form>
  </van-dialog>
</template>

<script>
import { getAbnormalInfo } from "@/api/psm/inspectionNew";

export default {
  name: "ProcessRecordDialog",
  props: ["checkId"],
  data() {
    return {
      info: {}
    };
  },
  methods: {
    onClose() {
      this.info = {};
    },
    onOpen() {
      this.getInfo();
    },
    async getInfo() {
      this.info = await getAbnormalInfo(this.checkId);
    }
  }
};
</script>

<style scoped></style>
