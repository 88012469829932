<template>
  <div class="inspection-exception-detail">
    <van-nav-bar left-arrow title="详情" fixed @click-left="$router.back()" />
    <van-form
      style="padding-top: 68px"
      :show-error-message="false"
      label-width="100px"
      class="van-form__text-color"
    >
      <van-field
        v-for="item in labelConf"
        :key="item.prop"
        disabled
        :label="item.label"
        colon
        :border="false"
        type="textarea"
        :rows="1"
        :autosize="true"
      >
        <template #input>
          <span>{{ getValue(item) }}</span>
        </template>
      </van-field>
    </van-form>
    <div class="btn-group">
      <van-button
        v-if="+info.abnormalWay !== 1 && btnAuth.trouble"
        type="info"
        :disabled="+info.abnormalWay === 2 || +info.abnormalWay === 3"
        @click="toTrouble('1')"
        >转为隐患</van-button
      >
      <van-button
        v-if="+info.abnormalWay !== 2 && btnAuth.fault"
        type="info"
        :disabled="+info.abnormalWay === 1 || +info.abnormalWay === 3"
        @click="toTrouble('2')"
        >转为故障</van-button
      >
      <van-button
        v-if="+info.abnormalWay !== 3 && btnAuth.inside"
        type="info"
        :disabled="+info.abnormalWay === 1 || +info.abnormalWay === 2"
        @click="toTrouble('3')"
        >内部处理</van-button
      >
      <van-button
        v-if="info && +info.abnormalWay === 1 && btnAuth.trouble"
        btn-auth-trouble
        type="info"
        @click="toTrouble('4')"
        >隐患跟踪</van-button
      >
      <van-button
        v-if="info && +info.abnormalWay === 2 && btnAuth.fault"
        type="info"
        @click="toTrouble('5')"
        >故障跟踪</van-button
      >
      <van-button
        v-if="info && +info.abnormalWay === 3 && btnAuth.inside"
        type="info"
        @click="toTrouble('6')"
        >处理记录</van-button
      >
    </div>
    <to-inner-dialog
      v-model="innerDialogVisible"
      :check-id="info.id"
      :error-types="errorTypes"
      @refresh="getInfo"
      @close="innerDialogVisible = false"
    />
    <!-- 详情 -->
    <trouble-track-dialog
      v-model="troubleTrackDialogVisible"
      :data-id="info.dataId"
      @close="troubleTrackDialogVisible = false"
    />
    <process-record-dialog
      v-model="processRecordDialogVisible"
      :check-id="info.id"
      @close="processRecordDialogVisible = false"
    />

    <!-- 转为故障 -->
    <to-fault-dialog
      v-model="faultDialogVisible"
      :error-types="errorTypes"
      :check-id="info.id"
      @refresh="getInfo"
      @close="faultDialogVisible = false"
    />
    <!-- 故障追踪 -->
    <fault-track-dialog
      v-model="faultTrackDialogVisible"
      :error-types="errorTypes"
      :check-id="info.dataId"
      @close="faultTrackDialogVisible = false"
    />
  </div>
</template>

<script>
import {
  getInfoInspectException,
  getSelectErrorClass
} from "@/api/psm/inspectionNew";
import dayjs from "dayjs";
import { dayjsFormatDateTemplate } from "@/utils/constant";
import { labelConf } from "./utils/contant";
import TroubleTrackDialog from "./components/TroubleTrackDialog";
import "./dialog.scss";
import ToInnerDialog from "@/views/InspectionException/components/ToInnerDialog";
import ProcessRecordDialog from "@/views/InspectionException/components/ProcessRecordDialog";
import ToFaultDialog from "@/views/InspectionException/components/ToFaultDialog";
import FaultTrackDialog from "@/views/InspectionException/components/FaultTrackDialog";
import { mapState } from "vuex";

export default {
  name: "InspectionExceptionDetail",
  components: {
    ProcessRecordDialog,
    ToInnerDialog,
    TroubleTrackDialog,
    ToFaultDialog,
    FaultTrackDialog
  },
  props: ["id"],
  data() {
    return {
      labelConf,
      info: {},
      errorTypes: [],
      faultDialogVisible: false,
      innerDialogVisible: false,
      troubleTrackDialogVisible: false,
      faultTrackDialogVisible: false,
      processRecordDialogVisible: false
    };
  },
  computed: {
    ...mapState({
      btnAuth: state => state.menu.btnAuth
    })
  },
  created() {
    this.getInfo();
    this.getTypes();
  },
  methods: {
    getTypes() {
      getSelectErrorClass().then(res => {
        this.errorTypes = res ?? [];
      });
    },
    async getInfo() {
      const info = await getInfoInspectException(this.id);
      const format2 = d => dayjs(d).format(dayjsFormatDateTemplate);
      info.time =
        format2(info.validStartTime) + " - " + format2(info.validEndTime);
      const format = d => dayjs(d).format("HH:mm");
      info.inspectionTimeBetween =
        format(info.validStartTime) + "-" + format(info.finalEndTime);

      if ([20, 30].includes(info.checkMethod)) {
        if (info.downBounds && info.upBounds) {
          info.checkStandardText = `合格范围: ${info.downBounds}${info.units} ~ ${info.upBounds}${info.units}`;
        } else if (info.downBounds) {
          info.checkStandardText = `合格范围: > ${info.downBounds}${info.units}`;
        } else if (info.upBounds) {
          info.checkStandardText = `合格范围: < ${info.upBounds}${info.units}`;
        }
      } else {
        info.checkStandardText = info.checkStandard;
      }
      this.info = info;
    },
    getValue(row) {
      const prop = row.prop;
      const info = this.info;
      if (row.dic) {
        return row.dic[info[prop]] || "--";
      }
      return info[prop] || "--";
    },
    toTrouble(type) {
      if (type === "1") {
        return this.$router.push({
          name: "InspectionExceptionTrouble",
          params: { id: this.id },
          query: { checkStandard: this.info.checkStandard }
        });
      }
      console.log(type, "type");
      const map = {
        2: "faultDialogVisible",
        3: "innerDialogVisible",
        4: "troubleTrackDialogVisible",
        5: "faultTrackDialogVisible",
        6: "processRecordDialogVisible"
      };
      this[map[type]] = true;
    }
  }
};
</script>

<style lang="scss">
.inspection-exception-detail {
  .van-cell {
    padding-bottom: 0;
  }
  .van-form {
    padding-bottom: 70px;
  }
  .btn-group {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    //margin-top: 36px;
    display: flex;
    justify-content: space-between;
    padding: 0 16px 20px;
    box-sizing: border-box;
    background-color: #fff;
    .van-button {
      width: 106px;
      height: 44px;
      border-radius: 27px;
    }
  }
}
</style>
